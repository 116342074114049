.calculator-header {
    display: grid;
    position: relative;
    background-color: var(--light-blue);
    height: auto;
    padding: 1.5vh 10px;
    font-size: 100%;
  }
  
  .calculator-header img {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  
  .calculator-header h1 {
    font-size: 2rem;
  }
  
  .calculator-header h3 {
    font-style: italic;
  }
  