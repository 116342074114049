/* Authors */
.authors-wrapper {
    font-size: 90%;
}


.author-card {
  display: grid;
  grid-template-columns: 1fr 4fr;
  padding: 15px;
  align-items: center;
}

.author-image {
  width: 60%;
  margin-left: 10%;
  border: 1px solid var(--green);
  border-radius: 20%;
}
