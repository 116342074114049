.question {
  margin: 13px 0;
  display: flex;
  font-size: 1.1rem;
  align-items: center;
}
  
.question-btn-yes,
.question-btn-no,
.question-btn {
  font-size: 14px;
  border: none;
  margin: 0px 10px;
  width: 60px;
  padding: 7px 1px;
  background-color: var(--light-blue);
  color: white;
  transition: all 0.2s ease;
}

.question-btn {
  width: fit-content;
  padding: 7px 10px;
}

.question-btn:hover,
.question-btn-yes:hover {
  background-color: var(--green);
  color: var(--dark-blue);
}

.question-btn-no:hover  {
  background-color: #ff4545;
  color: var(--dark-blue);
}
  
.question-dropdown {
  display: flex;
  flex-direction: column;
  height: 20px;
  width: auto;
  margin-left: 20px;
  background-color: var(--green);
  border: none;
}
  
.question p {
  margin: 10px 0px;
  font-size: 1rem;
  color: var(--green);
}


.question p span {
  color: white;
  margin-left: 10px;
}
  
.question input,
.question select {
  border: none;
  padding:  2px;
  margin-left: 20px;
  width: auto;
}

.question input {
  border-bottom: white 1px solid;
  background-color: transparent;
  width: 10vw;
  color: white;
}

.question input:focus {
  outline: none;
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.05);
}

.question select:focus {
  outline: none;
}


.question-text {
  text-align: center;
}

select {
  width: auto;
}
