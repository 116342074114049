.guide-grid {
    display: grid;
    padding: 5px;
}

.guide-grid-cell {
    height: auto;
    width: auto;
    border: 1px solid var(--green);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section {
    margin: 20px 0px;
}

.grid-text {
    white-space: pre-line;
}

.guide-image-cell-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}

.grid-image {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
}

.guide {
    margin: 30px 0px;
    border: 1px solid var(--green);
    padding: 20px 15px;
    font-size: 90%;
}

.to-top-btn {
    text-decoration: none;
    color: var(--dark-blue);
    background-color: var(--green);
    font-size: 1.3rem;
    padding: 3px 5px;
    transition: all 0.5s ease-in-out;
}

.to-top-btn:hover {
    cursor: pointer;
    background-color: var(--dark-blue);
    color: var(--green);
}

.guide-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.guide-header h3 {
    font-weight: bold;
    font-size: 1.3rem;
}